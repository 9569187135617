import { ChangeEvent, useEffect, useState } from "react";
import LoadingSpinner from "../elements/LoadingSpinner";
import axios from "axios";
import { API_URL } from "../../App";

const OrderDetails = ({
  accountType,
  orderDetails,
  setViewDetails,
  pickupDetails,
  setPickupDetails,
  handleApproveOrder,
  handleInitialApprove,
  error,
  setError,
  isLoading,
}: any) => {
  const [vendorsWithProduct, setVendorsWithProduct] = useState([]);
  const [tempPickupDetails, setTempPickupDetails] = useState<any>([]);
  const [initialPickupLocation, setInitialPickupLocation] = useState("");

  useEffect(() => {
    const details = orderDetails.orderItems.map((item: any) => {
      getVendorsWithProduct(item.productId);
      return { productId: item.productId, vendorId: "" };
    });

    setTempPickupDetails(details);
  }, []);

  useEffect(() => {
    setPickupDetails(tempPickupDetails);
  }, [tempPickupDetails]);

  const getVendorsWithProduct = async (productId: string) => {
    try {
      const res = await axios.get(`${API_URL}/vendors-product/${productId}`);
      setVendorsWithProduct((prev) => ({
        ...prev,
        [productId]: res.data,
      }));
    } catch (error: any) {
      console.error(error);
      setVendorsWithProduct((prev) => ({
        ...prev,
        [productId]: "not found",
      }));
    }
  };
  const handleClose = () => {
    setError({});
    setViewDetails(false);
  };

  const handleSelectVendor = (
    e: ChangeEvent<HTMLSelectElement>,
    productId: string
  ) => {
    setPickupDetails((prev: any) => {
      const updatedDetails = prev.map((item: any) => {
        if (item.productId === productId) {
          return { ...item, vendorId: e.target.value };
        }
        return item;
      });

      return updatedDetails;
    });
  };

  // const findPickupDetails =async(vendorId: string)=> {
  //   setVendorIsLoading(true)

  //   try {
  //     const res = await axios.get(`${API_URL}/vendors/${vendorId}`)
  //     console.log(res.data);
  //     setVendorIsLoading(false)
  //   } catch (error) {
  //     console.error(error)
  //     setVendorIsLoading(false)
  //   }
  // }

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 overflow-y-auto">
      <div
        className="bg-white p-4 rounded-md relative mx-auto my-10"
        style={{ width: "600px" }}
      >
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md">
          <h2 className="text-lg font-semibold">Order Details</h2>
          <button onClick={handleClose} className="text-dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <hr />
        <div className="mb-2">
          <table className="w-full mb-4">
            <thead className="text-left">
              <tr className="leading-10">
                <th></th>
                <th>Products</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>
                  {orderDetails.status !== "pending"
                    ? "Assigned vendors"
                    : "Assign vendors"}
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {orderDetails.orderItems.map((item: any, index: any) => (
                <tr key={index}>
                  <td className="border-b py-1 w-14">
                    <img className="w-full" src={item.image} alt={item.name} />
                  </td>
                  <td className="border-b py-1">{item.name}</td>
                  <td className="border-b py-1">{item.quantity}</td>
                  <td className="border-b py-1">{item.totalPrice}</td>
                  {orderDetails.status === "pending" && (
                    <td className="border-b py-1">
                      {vendorsWithProduct[item.productId] &&
                      Array.isArray(vendorsWithProduct[item.productId]) ? (
                        <>
                          <select
                            name="vendor"
                            id="vendors"
                            className="w-32 p-2 border-secondary border"
                            // disabled={orderDetails.status !== "pending"}
                            disabled
                            onChange={(e) =>
                              handleSelectVendor(e, item.productId)
                            }
                          >
                            <option value="">select</option>
                            {(
                              vendorsWithProduct[item.productId] as Array<any>
                            )?.map((item: any, index: number) => (
                              <option
                                key={index}
                                value={item._id}
                                title={item.address}
                              >
                                {item.fullName}
                              </option>
                            ))}
                          </select>
                        </>
                      ) : (
                        <p className="p-2 border-secondary border w-fit">
                          No vendor
                        </p>
                      )}
                    </td>
                  )}
                  {orderDetails.status !== "pending" && (
                    <td className="border-b py-1">
                      {item.pickUpDetails?.fullName || "loading"}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {error.pickupDetails && (
            <p className="text-sm text-red-500 text-right font-semibold">
              {error.pickupDetails}
            </p>
          )}
          <div className="text-sm leading-7">
            <p className="font-semibold text-right">
              Date of Order:{" "}
              {orderDetails.orderDate
                ? new Date(orderDetails.orderDate).toLocaleString()
                : new Date(orderDetails.createdAt).toLocaleString()}
            </p>
            <div className="flex items-center justify-between text-sm text-gray-600 mt-2">
              <p>Subtotal:</p>
              <p>₦{orderDetails?.subtotal}</p>
            </div>
            <div className="flex items-center justify-between text-sm text-gray-600">
              <p>VAT:</p>
              <p>₦{orderDetails?.vat}</p>
            </div>
            <div className="flex items-center justify-between text-sm text-gray-600">
              <p>Service Fee:</p>
              <p>₦{orderDetails?.serviceFee}</p>
            </div>
            {accountType === "flexible" && (
              <div className="flex items-center justify-between text-sm text-gray-600">
                <p>Interest:</p>
                <p>₦{orderDetails?.interest}</p>
              </div>
            )}
            <div className="flex items-center justify-between text-sm text-gray-600">
              <p>Delivery Fee:</p>
              <p>₦{orderDetails?.deliveryFee}</p>
            </div>
            <div className="flex items-center justify-between text-base text-green-900 font-semibold mb-2">
              <p>Total:</p>
              <p>₦{(orderDetails?.allItemsTotalPrice).toLocaleString()}</p>
            </div>
            <p>Address: {orderDetails?.deliveryDetails}</p>
            <p>Phone Number: {orderDetails?.userId?.phoneNumber} </p>
            <div>
              <label htmlFor="pickupLocation">Pickup Location:</label>
              {orderDetails.status !== "pending" ? null : (
                <>
                  <textarea
                    name="pickupLocation"
                    id="pickupLocation"
                    placeholder="Enter pickup location"
                    className="border border-secondary w-full p-2 text-sm"
                    value={initialPickupLocation}
                    onChange={(e) => setInitialPickupLocation(e.target.value)}
                  ></textarea>
                  {error.initialPickup && (
                    <p className="text-sm text-red-500 text-right font-semibold">
                      {error.initialPickup}
                    </p>
                  )}
                  <button
                    type="button"
                    className="bg-secondary text-sm rounded-md p-2 text-white"
                    onClick={() =>
                      handleInitialApprove(
                        orderDetails._id,
                        initialPickupLocation
                      )
                    }
                  >
                    Initial Approve
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {/* {orderDetails.status !== "pending" ? null : (
          <div className="p-2 flex justify-end gap-3">
            <button
              className={`${
                pickupDetails.some((item: any) => item.vendorId === "")
                  ? "bg-green-300"
                  : "bg-secondary"
              } p-2 rounded-md text-sm text-white w-20`}
              onClick={() => handleApproveOrder(orderDetails._id)}
              disabled={pickupDetails.some((item: any) => item.vendorId === "")}
            >
              {isLoading ? <LoadingSpinner /> : "Approve"}
            </button>
            <button
              className="p-2 rounded-md text-sm text-white bg-red-500"
              onClick={handleClose}
            >
              Decline
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default OrderDetails;
