import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { Preloader } from "../elements/Preloader";
import { API_URL } from "../../App";

export const DeleteUser = ({ userToDelete, handleCloseModal }: any) => {
  // const token = sessionStorage.getItem("token");
  // const [isLoading, setIsLoading] = useState(false);

  // const handleDeleteUser = async () => {
  //   const userId = userToDelete._id;
  //   setIsLoading((prev) => !prev);

  //   try {
  //     await axios.delete(
  //       `${API_URL}/forceDelete/${userId}`,
  //       {
  //         headers: {
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     setIsLoading(false);
  //     handleCloseModal()
  //     toast.success("User delete from database successfully!");
  //   } catch (error: any) {
  //     console.error(error);
  //     setIsLoading(false);
  //     toast.error("Could not delete user!");
  //   }
  // };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center flex-col text-dark p-2 rounded-t-md mb-4">
          <div className="relative w-full">
            <button
              onClick={handleCloseModal}
              className="text-dark absolute right-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <p className="font-bold text-center">Check Back Later...</p>
          </div>
          {/* <div>
            <h3 className="text-lg mt-5 my-3 font-semibold text-center">
              Are you sure you want to <strong>force delete</strong>{" "}
              {`${
                userToDelete.firstName + userToDelete.lastName ||
                userToDelete.email
              }`}
              ?
            </h3>
            <div className="flex gap-3 justify-center">
              <button
                type="button"
                className={`w-28 px-5 py-3 cursor-pointer rounded text-white bg-red-500 ${
                  isLoading && "pb-[1.1rem]"
                }`}
                onClick={handleDeleteUser}
              >
                {isLoading ? <Preloader /> : "Yes"}
              </button>
              <button
                type="button"
                className="w-28 px-5 py-3 cursor-pointer bg-secondary rounded text-white"
                onClick={handleCloseModal}
              >
                No
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
