import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllVendors } from "../../redux/thunk";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { useNavigate } from "react-router-dom";

const Vendors = () => {
  const dispatch = useAppDispatch();
  const { vendors, status } = useAppSelector((store) => store.vendors);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const filteredVendors = vendors.filter((vendor: any) =>
    vendor.fullName.toLowerCase().includes(search.toLowerCase())
  );

  console.log("vendors", vendors);

  useEffect(() => {
    dispatch(getAllVendors());
  }, []);

  return (
    <main>
      <div className="flex justify-between">
        <h2 className="font-bold text-lg">Vendors</h2>
        {/* <button
          type="button"
          className="bg-secondary rounded-md text-white text-sm p-3"
          onClick={() => navigate("add-new-restaurant")}
        >
          Add New Restaurant
        </button> */}
      </div>
      <section className="flex mt-5 justify-evenly flex-wrap gap-3">
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Vendors
          </h2>
          <p className="font-semibold text-3xl">{vendors.length}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Approved Vendors
          </h2>
          <p className="font-semibold text-3xl">{}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Vendors pending
          </h2>
          <p className="font-semibold text-3xl">{}</p>
        </div>
      </section>
      <section className="my-5 overflow-x-scroll">
        <section className="md:w-full w-[700px]">
          <div className="flex justify-between items-center w-full bg-white rounded-lg p-3 mb-2 gap-2">
            <h1 className="font-semibold ">All Vendors</h1>
            <input
              type="search"
              name="search"
              id="search"
              className="border text-sm w-1/2 p-2 rounded"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search by name"
            />
          </div>
          {status === "pending" ? (
            <LoadingSpinner />
          ) : (
            <table className="w-full text-left">
              <thead>
                <tr className="bg-gray-50 font-bold h-12 md:text-base text-sm">
                  <th>SN</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {vendors.length > 0 ? (
                  filteredVendors.map((item: any, index: any) => (
                    <tr key={index} className="border-b-2 hover:bg-gray-200">
                      <td className="p-4 md:text-base text-sm">{index + 1}</td>
                      <td
                        className="cursor-pointer md:text-base text-sm"
                        onClick={() =>
                          navigate("vendor-information", {
                            state: { item },
                          })
                        }
                      >
                        {item.fullName}
                      </td>
                      <td
                        className="cursor-pointer md:text-base text-sm"
                        onClick={() =>
                          navigate("vendor-information", {
                            state: { item },
                          })
                        }
                      >
                        {item.phoneNumber}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="bg-secondary text-white text-sm p-2 rounded-md"
                          onClick={() =>
                            navigate("settlement", {
                              state: { name: item.fullName },
                            })
                          }
                        >
                          Settlement
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="p-5 bg-white text-center text-secondary"
                    >
                      No Vendors!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </section>
      </section>
    </main>
  );
};

export default Vendors;
