import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { API_URL } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

const AddRestaurant = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [formInputs, setFormInputs] = useState<{
    restaurantName: string;
    restaurantEmail: string;
    restaurantLocation: string;
    openingTime: any;
    closingTime: any;
    restaurantImage: [];
  }>({
    restaurantName: "",
    restaurantEmail: "",
    restaurantLocation: "",
    openingTime: "",
    closingTime: "",
    restaurantImage: [],
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormInputs((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  console.log(formInputs);
  

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setFormInputs((prev: any) => ({
        ...prev,
        restaurantImage: [
          ...prev.restaurantImage,
          ...Array.from(e.target.files),
        ],
      }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formInputs.restaurantEmail)) {
      newErrors.restaurantEmail = "Please enter a valid email address";
      isValid = false;
    }

    if (!formInputs.restaurantName) {
      newErrors.restaurantName = "Please enter restaurant name";
      isValid = false;
    }

    if (!formInputs.restaurantLocation) {
      newErrors.restaurantLocation = "Please enter restaurant location";
      isValid = false;
    }

    if (formInputs.restaurantImage.length < 2) {
      newErrors.logo = "Please add restaurant logo";
      isValid = false;
    }

    if (formInputs.restaurantImage.length < 2) {
      newErrors.license = "Please upload CAC license";
      isValid = false;
    }

    if (!formInputs.openingTime) {
      newErrors.openingTime = "Please enter restaurant opening time";
      isValid = false;
    }

    if (!formInputs.closingTime) {
      newErrors.closingTime = "Please enter restaurant closing time";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("restaurantName", formInputs.restaurantName);
      formData.append("restaurantEmail", formInputs.restaurantEmail);
      formData.append("restaurantLocation", formInputs.restaurantLocation);
      formData.append("openingTime", formInputs.openingTime);
      formData.append("closingTime", formInputs.closingTime);

      formInputs.restaurantImage.forEach((image: any) =>
        formData.append("restaurantImage", image, image.name)
      );
      try {
        const res = await axios.post(
          `${API_URL}/registerRestaurant`,
          formData,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log(res);
        setFormInputs({
          restaurantName: "",
          restaurantEmail: "",
          restaurantLocation: "",
          openingTime: "",
          closingTime: "",
          restaurantImage: [],
        });
        toast.success(res.data.message || "Restaurant added successfully!");
        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        toast.error("Failed to add restaurant!");
        setIsLoading(false);
      }
    }
  };

  return (
    <main>
      <h2 className="font-semibold text-lg">Add New Restaurant</h2>
      <section className="bg-white py-5 my-5 rounded-md">
        <form className="w-11/12 mx-auto">
          <div className="my-3">
            <label className="font-medium" htmlFor="restaurantName">
              Restaurant Name:
            </label>
            <input
              className="block p-2 w-full mt-2 border rounded-sm"
              type="text"
              name="restaurantName"
              id="restaurantName"
              value={formInputs.restaurantName}
              onChange={handleInputChange}
            />
          </div>
          {errors.restaurantName && (
            <p className="text-red-500 text-xs text-right">
              {errors.restaurantName}
            </p>
          )}
          <div className="my-3">
            <label className="font-medium" htmlFor="restaurantEmail">
              Restaurant Email:
            </label>
            <input
              className="block p-2 w-full mt-2 border rounded-sm"
              type="email"
              name="restaurantEmail"
              id="restaurantEmail"
              value={formInputs.restaurantEmail}
              onChange={handleInputChange}
            />
          </div>
          {errors.restaurantEmail && (
            <p className="text-red-500 text-xs text-right">
              {errors.restaurantEmail}
            </p>
          )}
          <div className="my-3">
            <label className="font-medium" htmlFor="restaurantLocation">
              Restaurant Location:
            </label>
            <input
              className="block p-2 w-full mt-2 border rounded-sm"
              type="text"
              name="restaurantLocation"
              id="restaurantLocation"
              value={formInputs.restaurantLocation}
              onChange={handleInputChange}
            />
          </div>
          {errors.restaurantLocation && (
            <p className="text-red-500 text-xs text-right">
              {errors.restaurantLocation}
            </p>
          )}
          <div className="flex gap-20">
            <div className="my-3">
              <label className="font-medium" htmlFor="openingTime">
                Restaurant Opening Time:
              </label>
              <input
                className="block p-2 w-fit mt-2 border rounded-sm"
                type="time"
                name="openingTime"
                id="openingTime"
                value={formInputs.openingTime}
                onChange={handleInputChange}
              />
              {errors.openingTime && (
                <p className="text-red-500 text-xs text-right">
                  {errors.openingTime}
                </p>
              )}
            </div>
            <div className="my-3">
              <label className="font-medium" htmlFor="closingTime">
                Restaurant Closing Time:
              </label>
              <input
                className="block p-2 w-fit mt-2 border rounded-sm"
                type="time"
                name="closingTime"
                id="closingTime"
                value={formInputs.closingTime}
                onChange={handleInputChange}
              />
              {errors.closingTime && (
                <p className="text-red-500 text-xs text-right">
                  {errors.closingTime}
                </p>
              )}
            </div>
          </div>
          <div className="my-3">
            <label className="font-medium" htmlFor="logo">
              Upload Restaurant Logo:
            </label>
            <input
              className="block p-2 w-full mt-2 border rounded-sm"
              type="file"
              name="logo"
              id="logo"
              onChange={handleFileChange}
            />
          </div>
          {errors.logo && (
            <p className="text-red-500 text-xs text-right">{errors.logo}</p>
          )}
          <div className="my-3">
            <label className="font-medium" htmlFor="license">
              Upload CAC license:
            </label>
            <input
              className="block p-2 w-full mt-2 border rounded-sm"
              type="file"
              name="license"
              id="license"
              onChange={handleFileChange}
            />
          </div>
          {errors.license && (
            <p className="text-red-500 text-xs text-right">{errors.license}</p>
          )}
          <div className="text-center my-10">
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
              className="bg-secondary p-2 text-white w-2/3 mx-auto cursor-pointer"
            >
              {isLoading ? <LoadingSpinner /> : "Add Restaurant"}
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default AddRestaurant;
