import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../App";
import toast from "react-hot-toast";
import { Preloader } from "../../components/elements/Preloader";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

const CreditScorePage = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const creditInsightString = sessionStorage.getItem("creditInsights");
  const previousCreditInsight = creditInsightString
    ? JSON.parse(creditInsightString)
    : null;
  const [selectedBvn, setSelectedBvn] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateBVN = () => {
    let isValid = true;
    let newError: string = "";
    if (selectedBvn.trim() === "") {
      newError = "Please enter BVN";
      isValid = false;
    }
    if (selectedBvn.length < 11) {
      newError = "BVN should be 11 digits";
      isValid = false;
    }
    const onlyNumbersRegex = /^\d+$/;
    if (!onlyNumbersRegex.test(selectedBvn)) {
      newError = "BVN should contain only numbers";
      isValid = false;
    }
    setError(newError);
    return isValid;
  };

  const handleGenerateCreditScore = async () => {
    if (!validateBVN()) return;
    setIsLoading(true);
    const payload = { bvn: selectedBvn };
    try {
      const res = await axios.post(
        `${API_URL}/customers/credit-score`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsLoading(false);
      sessionStorage.setItem(
        "creditInsights",
        JSON.stringify(res.data)
      );
      navigate("credit-details", { state: { response: res.data } });
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to generate credit score");
      }
    }
  };

  return (
    <div className="bg-white p-5">
      <h1 className="font-bold text-xl">Credit Insights</h1>

      <section>
        <section className="flex items-end mt-3 w-full">
          <div className="w-1/3">
            <label htmlFor="bvn" className="font-semibold">
              BVN
            </label>
            <input
              type="text"
              name="bvn"
              id="bvn"
              value={selectedBvn}
              onChange={(e) => setSelectedBvn(e.target.value)}
              placeholder="Enter BVN"
              className="block text-base mt-2 rounded-l-md p-3 bg-transparent border w-full"
            />
          </div>
          <button
            type="button"
            className="p-3 w-24 h-[3.1rem] text-base border text-white rounded-r-md bg-secondary"
            onClick={handleGenerateCreditScore}
          >
            {isLoading ? <Preloader /> : "Generate"}
          </button>
        </section>
        {error && <p className="text-red-500 text-sm">{error}</p>}

        <section className="my-4">
          <h2 className="font-semibold text-lg">History</h2>

          <section className="my-3">
            <div className="bg-secondary p-4 rounded-t-md text-white">
              <h3 className="font-semibold">Credit insights</h3>
              <p className="text-sm">View your previous credit insights</p>
            </div>
            <table className="w-full border">
              <thead className="text-left border-b">
                <tr>
                  <th className="p-4">Customer</th>
                  <th className="p-4">Bvn</th>
                  <th className="p-4">Date</th>
                  <th className="p-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {previousCreditInsight ? (
                  <tr>
                    <td className="p-4">
                      <p>{previousCreditInsight.data.name}</p>
                      <p>{previousCreditInsight.data.phone}</p>
                    </td>
                    <td className="p-4">{previousCreditInsight.data.bvn}</td>
                    <td className="p-4">
                      {new Date(
                        previousCreditInsight.data.searchedDate
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </td>
                    <td className="p-4">
                      <button
                        type="button"
                        className="border bg-secondary p-2 text-sm text-white"
                        onClick={() =>
                          navigate("credit-details", {
                            state: { response: previousCreditInsight },
                          })
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center p-4">
                      No record
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* <div className="flex justify-between items-center p-2 border-x border-b">
              <button type="button" className="bg-secondary p-2 text-white">
                ← Prev
              </button>
              <button type="button" className="bg-secondary p-2 text-white">
                Next →
              </button>
            </div> */}
          </section>
        </section>
      </section>
    </div>
  );
};

export default CreditScorePage;
