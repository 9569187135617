import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { IoAdd } from "react-icons/io5";
import OutrightCustomers from "../../components/OutrightCustomers";
import IncompleteAccounts from "../../components/IncompleteAccounts";
import { AllBanksModal } from "../../components/modals/AllBanksModal";
import { AddBankModal } from "../../components/modals/AddBankModal";
import BVNVerificationModal from "../../components/modals/BVNVerificationModal";
import SelfEmployed from "../../components/modals/SelfEmployed";
import Employed from "../../components/modals/Employed";
import Student from "../../components/modals/Student";
import { Preloader } from "../../components/elements/Preloader";

const UserDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedBvn, setSelectedBvn] = useState("");
  const [selectedInfo, setSelectedInfo] = useState<any>({});
  const [customers, setCustomers] = useState([]);
  const [outright, setOutright] = useState([]);
  const [activeTab, setActiveTab] = useState("flexible");
  const [isLoading, setIsLoading] = useState<{
    main: boolean;
    delete: boolean;
  }>({
    main: false,
    delete: false,
  });
  const [isFlexibleLoading, setIsFlexibleLoading] = useState(false);
  const [selfEmployedModalOpen, setSelfEmployedModalOpen] = useState(false);
  const [allBanksModal, setAllBanksModal] = useState(false);
  const [allBanksDetails, setAllBanksDetails] = useState([]);
  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [incompleteUserLoading, setIncompleteUserLoading] = useState(false);
  const [addBankModal, setAddBankModal] = useState(false);
  const [incomplete, setIncomplete] = useState<any>([]);
  const [userToAddBank, setUserToAddBank] = useState({});
  const [filterIncomplete, setFilterIncomplete] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isActive, setIsActive] = useState("day");

  useEffect(() => {
    const filterDataByDate = () => {
      setFilteredData(
        incomplete.filter((data: any) => {
          const acctDate = new Date(data.createdAt).toLocaleDateString();
          const selectedDate = new Date(filterByDate).toLocaleDateString();
          return acctDate === selectedDate;
        })
      );
    };
    filterDataByDate();
  }, [filterByDate, incomplete]);

  useEffect(() => {
    const filterData = () => {
      setFilteredData(
        incomplete.filter((data: any) => {
          const currentDate = new Date();
          const apiDate = new Date(data.createdAt);
          const timeDifference = currentDate.getTime() - apiDate.getTime();
          const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

          if (filterIncomplete === "yesterday") {
            return daysDifference === 1;
          } else if (filterIncomplete === "aWeek") {
            return daysDifference >= 2 && daysDifference <= 7;
          } else if (filterIncomplete === "older") {
            return daysDifference > 7;
          } else {
            return true;
          }
        })
      );
    };
    filterData();
  }, [filterIncomplete, incomplete]);

  const getAllIncompleteUsers = async () => {
    setIncompleteUserLoading(true);
    try {
      const res = await axios.get(`${API_URL}/incompleteRegisteration`, {
        headers: {
          Authorization: token,
        },
      });
      setIncomplete(res.data);
      setIncompleteUserLoading(false);
    } catch (error) {
      console.error(error);
      setIncompleteUserLoading(false);
    }
  };

  useEffect(() => {
    getFlexibleCustomers();
    getOutrightCustomers();
    getAllIncompleteUsers();
  }, []);

  const handleBvnClick = (bvn: string) => {
    setSelectedBvn(bvn);
    setBvnModalOpen(true);
  };

  const handleViewAllBank = (id: string, linkedBanks: any) => {
    setUserId(id);
    setAllBanksDetails(linkedBanks);
    setAllBanksModal((prev) => !prev);
  };

  const handleJobClick = (details: string, occupation: any) => {
    setSelectedInfo(details);
    if (occupation === "Self-Employed") {
      setSelfEmployedModalOpen(true);
    } else if (occupation === "Employed") {
      setEmployedModalOpen(true);
    } else {
      setStudentModalOpen(true);
    }
  };

  const getFlexibleCustomers = async () => {
    setIsLoading((prev) => ({ ...prev, main: true }));
    try {
      const res = await axios.get(`${API_URL}/getapprovedCustomers`, {
        headers: {
          Authorization: token,
        },
      });
      setIsLoading((prev) => ({ ...prev, main: false }));
      setCustomers(res.data);
    } catch (error) {
      console.log(error);
      setCustomers([]);
      setIsLoading((prev) => ({ ...prev, main: false }));
    }
  };

  const getOutrightCustomers = async () => {
    setIsFlexibleLoading(true);
    try {
      const res = await axios.get(`${API_URL}/getOutrightCustomers`, {
        headers: {
          Authorization: token,
        },
      });
      setIsFlexibleLoading(false);
      setOutright(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
      setIsFlexibleLoading(false);
      setOutright([]);
    }
  };

  const handleAddBank = (firstName: any, lastName: any, id: string) => {
    setUserToAddBank({ name: lastName + " " + firstName, id });
    setAddBankModal((prev) => !prev);
  };

  return (
    <main className="overflow-x-auto w-full">
      <div className="flex gap-10 mb-6">
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "outright" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "incomplete" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("incomplete")}
        >
          Incomplete Accounts
        </span>
      </div>
      {activeTab === "flexible" ? (
        <div className="bg-white rounded-md shadow-md pb-6">
          <div
            className={`w-full overflow-x-auto ${
              isLoading.main && "animate-pulse h-[50vh]"
            }`}
          >
            <div
              className="flex items-center justify-between p-6"
              style={{ minWidth: "700px" }}
            >
              <div className="flex justify-between w-full">
                <h1 className="text-base font-semibold ">User Details</h1>
                <label htmlFor="filter">
                  Sort:
                  <select
                    name="filter"
                    id="filter"
                    className="border p-2 ml-4"
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="employed">Employed</option>
                    <option value="self-employed">Self-employed</option>
                    <option value="student">Students</option>
                  </select>
                </label>
              </div>
            </div>
            <section className="overflow-x-auto">
              <table className="w-[1200px]" style={{ minWidth: "700px" }}>
                <thead className="bg-gray-50 font-bold p-4 text-left">
                  {filter === "student" && (
                    <tr>
                      <th className="p-2">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Education</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>{" "}
                    </tr>
                  )}
                  {filter === "employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Job</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>{" "}
                    </tr>
                  )}
                  {filter === "all" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Occupation</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>{" "}
                    </tr>
                  )}
                  {filter === "self-employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2 ">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Business</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>{" "}
                    </tr>
                  )}
                </thead>
                <tbody className="px-4">
                  {customers
                    .filter((customer: any) => {
                      if (filter === "employed") {
                        return customer.employmentStatus === "employed";
                      } else if (filter === "self-employed") {
                        return customer.employmentStatus === "selfEmployed";
                      } else if (filter === "student") {
                        return customer.employmentStatus === "student";
                      } else if (filter === "all") {
                        return customer.employmentStatus;
                      }
                      return true;
                    })
                    .map((data: any, index) => {
                      const filteredJob =
                        data.employmentStatus === "employed"
                          ? "Employed"
                          : data.employmentStatus === "selfEmployed"
                          ? "Self-Employed"
                          : data.employmentStatus === "student"
                          ? "Student"
                          : null;

                      const filteredJobObject =
                        data.employmentStatus === "employed"
                          ? data.employed
                          : data.employmentStatus === "selfEmployed"
                          ? data.selfEmployed
                          : data.employmentStatus === "student"
                          ? data.student
                          : null;
                      return (
                        <tr
                          className="border-b border-gray-300 py-2"
                          key={index}
                        >
                          <td className="text-secondary p-2">{index + 1}</td>
                          <td className="p-2 text-nowrap">
                            {data.lastName} {data.firstName}
                          </td>
                          <td className="p-2">{data.email}</td>

                          <td className="p-2">
                            {data.phoneNumber ? data.phoneNumber : "No number"}
                          </td>
                          <td className="p-2">
                            <button
                              onClick={() => handleBvnClick(data)}
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {data.bvn}
                            </button>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className="text-secondary border"
                              onClick={() =>
                                handleAddBank(
                                  data.firstName,
                                  data.lastName,
                                  data._id
                                )
                              }
                            >
                              <IoAdd className="w-9 h-9" />
                            </button>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className="bg-secondary text-white text-sm rounded-md p-2"
                              onClick={() =>
                                handleViewAllBank(data._id, data.linkedBanks)
                              }
                            >
                              View
                            </button>
                          </td>
                          <td className="p-2 text-nowrap">
                            <button
                              onClick={() =>
                                handleJobClick(filteredJobObject, filteredJob)
                              }
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {filteredJob}
                            </button>
                          </td>
                          <td className="p-2">
                            ₦{data.creditScore.toLocaleString()}
                          </td>
                          <td className="p-2">
                            ₦{data.creditLimit.toLocaleString()}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </section>
          </div>
        </div>
      ) : activeTab === "outright" ? (
        <div className="bg-white rounded-md shadow-md pb-6">
          <div
            className={`w-full overflow-x-auto ${
              isLoading.main && "animate-pulse h-[50vh]"
            }`}
          >
            <div
              className="flex items-center justify-between p-6"
              style={{ minWidth: "700px" }}
            >
              <div className="flex justify-between w-full">
                <h1 className="text-base font-semibold ">Outright Customers</h1>
              </div>
            </div>
            <section className="overflow-x-auto">
              <table className="w-[1020px]" style={{ minWidth: "700px" }}>
                <thead className="bg-gray-50 font-bold p-4 text-left">
                  <tr>
                    <th className="p-2 ">S/N</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Email</th>
                    <th className="p-2">Phone number</th>
                    <th className="p-2">Address</th>
                    <th className="p-2 text-nowrap">Verification stage</th>
                  </tr>
                </thead>
                <tbody className="px-4">
                  {outright.map((data: any, index: number) => {
                    return (
                      <tr className="border-b border-gray-300 py-2" key={index}>
                        <td className="text-secondary p-2">{index + 1}</td>
                        <td className="p-2 text-nowrap">
                          {data.firstName ? data.lastName : null}{" "}
                          {data.lastName ? data.firstName : null}
                        </td>
                        <td className="p-2 text-nowrap">{data.email}</td>
                        <td className="p-2 text-nowrap">
                          {data?.phoneNumber || "No number"}
                        </td>
                        <td className="p-2 text-nowrap">
                          {data.deliveryAddress ? data.deliveryAddress : null}
                        </td>
                        <td className="p-2 text-nowrap">{data.isComplete}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-md shadow-md pb-6">
          <div className="flex justify-between w-full p-3">
            <h1 className="text-base font-semibold p-3">Incomplete Accounts</h1>
            <div>
              <label htmlFor="filterByDate">
                Filter by date:
                <input
                  type="date"
                  name="filterByDate"
                  id="filterByDate"
                  className={`border p-2 mx-4 ${
                    isActive === "date" && "bg-gray-200"
                  }`}
                  onClick={() => setIsActive("date")}
                  onChange={(e) => setFilterByDate(e.target.value)}
                />
              </label>
              <label htmlFor="filterIncomplete">
                Sort:
                <select
                  name="filterIncomplete"
                  id="filterIncomplete"
                  className={`border p-2 mx-4 ${
                    isActive === "day" && "bg-gray-200"
                  }`}
                  onClick={() => setIsActive("day")}
                  onChange={(e) => setFilterIncomplete(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="aWeek">A week ago</option>
                  <option value="older">Older</option>
                </select>
              </label>
            </div>
          </div>

          <section className="overflow-x-auto">
            <table className="w-[1200px]" style={{ minWidth: "700px" }}>
              <thead className="bg-gray-50 font-bold p-4 text-left text-nowrap">
                <tr>
                  <th className="px-2 py-4 ">S/N</th>
                  <th className="px-2 py-4 ">Name</th>
                  <th className="px-2 py-4">Email</th>
                  <th className="px-2 py-4">Phone Number</th>
                  <th className="px-2 py-4">Employment status</th>
                  <th className="px-2 py-4">Address</th>
                  <th className="px-2 py-4">Account Type</th>
                  <th className="px-2 py-4">Verification stage</th>
                  <th className="px-2 py-4">Created on</th>
                </tr>
              </thead>
              <tbody className="px-4 text-nowrap">
                {incompleteUserLoading ? (
                  <LoadingSpinner />
                ) : filteredData.length > 0 ? (
                  filteredData.map((data: any, index: number) => {
                    return (
                      <tr
                        className="border-b border-gray-300 py-2 hover:bg-gray-50"
                        key={index}
                      >
                        <td className="text-secondary p-2">{index + 1}</td>
                        <td className="p-2">
                          {data.firstName && data.lastName
                            ? `${data.firstName} ${data.lastName}`
                            : "Not available"}
                        </td>
                        <td className="p-2">{data.email}</td>
                        <td className="p-2">
                          {data.phoneNumber
                            ? data.phoneNumber
                            : "Not available"}
                        </td>
                        <td className="p-2 lowercase">
                          {data.employmentStatus
                            ? data.employmentStatus
                            : "Not available"}
                        </td>
                        <td className="p-2 capitalize">
                          {data.address
                            ? data.address.toLowerCase()
                            : "Not available"}
                        </td>
                        <td className="p-2">{data.accountType}</td>
                        <td className="p-2">{data.isComplete}</td>
                        <td className="p-2">
                          {new Date(data.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="text-center text-secondary p-5">
                      No user available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      )}

      {allBanksModal && (
        <AllBanksModal
          setAllBanksModal={setAllBanksModal}
          setAllBanksDetails={setAllBanksDetails}
          allBanksDetails={allBanksDetails}
          flexibleCustomersFunc={getFlexibleCustomers}
          userId={userId}
        />
      )}

      {addBankModal && (
        <AddBankModal
          setAddBankModal={setAddBankModal}
          userToAddBank={userToAddBank}
        />
      )}

      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
        />
      )}

      {selfEmployedModalOpen && (
        <SelfEmployed
          setSelfEmployedModalOpen={setSelfEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {employedModalOpen && (
        <Employed
          setEmployedModalOpen={setEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {studentModalOpen && (
        <Student
          selectedInfo={selectedInfo}
          setStudentModalOpen={setStudentModalOpen}
        />
      )}
    </main>
  );
};

export default UserDetails;
