const Employed = ({ setEmployedModalOpen, selectedInfo }: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">Employment Information </h2>
          <button
            onClick={() => setEmployedModalOpen(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className=" border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">company Name:</h4>{" "}
          <h5>{selectedInfo.companyName}</h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">company Location:</h4>{" "}
          <h5>{selectedInfo.companyLocation}</h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">company Phone:</h4>{" "}
          <h5>{selectedInfo.companyPhone} </h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">industryType</h4>{" "}
          <h5>{selectedInfo.industryType}</h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">position In Company</h4>{" "}
          <h5>{selectedInfo.positionInCompany}</h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">salary amount</h4>{" "}
          <h5>{selectedInfo.monthlySalary} </h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">salary Account BankName</h4>{" "}
          <h5>
            {selectedInfo.salaryAccountBankName
              ? selectedInfo.salaryAccountBankName
              : selectedInfo.salaryAccounts[0].salaryAccountBankName}{" "}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">salary Account Number</h4>{" "}
          <h5>
            {selectedInfo.salaryAccountNumber
              ? selectedInfo.salaryAccountNumber
              : selectedInfo.salaryAccounts[0].salaryAccountNumber}{" "}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">salaryAccountName</h4>{" "}
          <h5>
            {selectedInfo.salaryAccountName
              ? selectedInfo.salaryAccountName
              : selectedInfo.salaryAccounts[0].salaryAccountName}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-5 flex justify-between">
          <h4 className="font-semibold capitalize">Work ID</h4>
          <a
            className="w-1/2 truncate"
            href={selectedInfo.workIDCard[0]}
            target="blank"
          >
            {selectedInfo.workIDCard[0]}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Employed;
