import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { API_URL } from "../../App";
import { Preloader } from "../elements/Preloader";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

const ChangePassword = ({ setShowChangePassword }: any) => {
  const [errors, setErrors] = useState<any>({});
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [inputValue, setInputValue] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (inputValue.currentPassword.length === 0) {
      newErrors.currentPassword = "Please enter your current password";
      isValid = false;
    }

    if (inputValue.newPassword.length === 0) {
      newErrors.newPassword = "Please enter your new password";
      isValid = false;
    }

    if (inputValue.confirmPassword.length === 0) {
      newErrors.confirmPassword = "Please confirm new password";
      isValid = false;
    }

    if (inputValue.newPassword !== inputValue.confirmPassword) {
      newErrors.unmatch = "The new password does not match";
      isValid = false;
    }

    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(inputValue.newPassword)) {
      newErrors.newPassword =
        "Password must be at least 8 characters and contain both letters and numbers";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendMessage = async (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      const payload = inputValue;
      try {
        const res = await axios.post(`${API_URL}/change-password`, payload, {
          headers: {
            Authorization: token,
          },
        });
        toast.success(res.data.message || "Changed successfully");
        setIsLoading(false);
        setShowChangePassword(false);
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error.message || "Failed to change password");
      }
    }
  };

  return (
    <div className="fixed inset-0 z-20 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div
        className="bg-white p-4 rounded-md overflow-auto"
        style={{ width: "500px", height: "400px" }}
      >
        <div className="flex justify-between items-center text-dark py-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">Change Password</h2>
          <button
            onClick={() => setShowChangePassword(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section className="flex justify-between flex-col gap-3">
          <div className="flex gap-3 items-center justify-between">
            <label htmlFor="currentPassword">Current Password</label>
            <input
              type="password"
              name="currentPassword"
              id="currentPassword"
              className="border border-secondary p-2 rounded"
              value={inputValue.currentPassword}
              onChange={(e) =>
                setInputValue((prev) => ({
                  ...prev,
                  currentPassword: e.target.value,
                }))
              }
            />
          </div>
          {errors.currentPassword && (
            <p className="text-red-500 text-xs text-right">
              {errors.currentPassword}
            </p>
          )}
          <div className="flex gap-3 items-center justify-between">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              name="newPassword"
              id="newPassword"
              className="border border-secondary p-2 rounded"
              value={inputValue.newPassword}
              onChange={(e) =>
                setInputValue((prev) => ({
                  ...prev,
                  newPassword: e.target.value,
                }))
              }
            />
          </div>
          {errors.newPassword && (
            <p className="text-red-500 block text-xs text-right">
              {errors.newPassword}
            </p>
          )}
          <div className="flex gap-3 items-center justify-between">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              className="border border-secondary p-2 rounded"
              value={inputValue.confirmPassword}
              onChange={(e) =>
                setInputValue((prev) => ({
                  ...prev,
                  confirmPassword: e.target.value,
                }))
              }
            />
          </div>
          {errors.unmatch && (
            <p className="text-red-500 text-xs text-right">{errors.unmatch}</p>
          )}

          <button
            type="button"
            className="p-2 bg-secondary text-sm text-white cursor-pointer"
            onClick={(e) => sendMessage(e)}
          >
            {isLoading ? <Preloader /> : "Save"}
          </button>
        </section>
      </div>
    </div>
  );
};

export default ChangePassword;
