import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useSocket } from "../../redux/SocketContext";

const CustomerChat = () => {
  const { uid } = useAppSelector((store: RootState) => store.auth);
  const socket: any = useSocket();
  const [typedMessage, setTypedMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [allChats, setAllChats] = useState<any>([]);
  const [messagePayload, setMessagePayload] = useState<any>([]);
  const userId = "s302840";
  const [selectedChat, setSelectedChat] = useState({
    _id: "",
    name: "",
    lastMessage: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    if (!socket) return;
    console.log("uid", uid);

    socket.emit("joinRoom", { uid });
    socket.on("receiveMessage", (data: any) => {
      console.log(data);
    });
    socket.emit("getChatHistory", { userId: "672e0a080d3f650992d80689", contactId: uid });
    socket.on("chatHistory", (data: any) => {
      console.log("chatHistory", data);

      // const messageWithTimestamp = {
      //   ...data,
      //   createdAt: new Date(),
      //   id: data._id || Date.now(),
      // };
      // setMessagePayload((prevMessages: any) => [
      //   ...prevMessages,
      //   messageWithTimestamp,
      // ]);
    });

    socket.emit("getCustomersOnChatHistory", { });
    socket.on("customersOnChatHistory", (chatHistory: any) => {
      console.log("allChats", chatHistory);
      setAllChats(chatHistory);
    });

    return () => {
      socket.off("customersOnChatHistory");
      socket.off("receiveMessage");
    };
  }, [socket, uid]);

  const handleSendMessage = async () => {
    if (typedMessage.trim() !== "" && socket) {
      const message = {
        sender: uid,
        receiver: selectedChat?._id,
        message: typedMessage,
      };
      await socket.emit("sendMessage", message);
      setTypedMessage("");
    }
  };

  const getCustomerMessage = (receiverId: any) => {
    console.log("receiverId", receiverId, uid, socket);
    if (socket) {
      socket.emit("getChatHistory", { userId: receiverId, contactId: uid });
      socket.on("chatHistory", (chatHistory: any) => {
        const chatHistoryWithTimestamps = chatHistory.map((message: any) => ({
          ...message,
          createdAt: new Date(message.createdAt),
          id: message._id || Date.now(),
        }));
        console.log(chatHistoryWithTimestamps);
      });
    }
  };

  return (
    <div>
      <h1 className="text-lg font-bold">Customer Chats</h1>
      <section className="flex gap-3 bg-white p-5 my-3 rounded-md">
        <div className="w-1/3 border rounded-md">
          <div className="flex justify-between items-center px-2 py-4 shadow-md">
            <h3 className="font-semibold text-lg">All Chats</h3>
            <span className="bg-secondary text-white p-1 rounded-full w-7 text-center text-xs">
              {allChats.length}
            </span>
          </div>
          <div className="md:h-[26rem] overflow-auto">
            {allChats && allChats.length > 0 ? (
              allChats.map((data: any, index: number) => (
                <div
                  key={index}
                  onClick={() => getCustomerMessage(data._id)}
                  className="border-y p-2 py-5 my-2 flex items-center hover:bg-gray-100 cursor-pointer"
                >
                  <img
                    src={data.image}
                    alt={data.name}
                    className="w-10 h-10 rounded-full border border-green-100"
                  />
                  <div className="pl-3">
                    <p className="text-sm text-semibold">{data.name}</p>
                    <p className="text-xs text-grey-500">{data.lastMessage}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-secondary text-center p-3">
                No available chat
              </p>
            )}
          </div>
        </div>
        <section className="border w-2/3 p-2 relative">
          {Object.values(selectedChat).every((value) => value !== "") ? (
            <>
              <div className="flex justify-between border-b py-3">
                <h2>{selectedChat.name}</h2>
                <p>{selectedChat.date}</p>
              </div>

              <div>
                <div className="border rounded-md p-2 bg-secondary text-white w-1/3 my-2 text-sm">
                  <p>{selectedChat.lastMessage}</p>
                  <p className="text-xs text-right">{selectedChat.time}</p>
                </div>
              </div>

              <div className="absolute flex justify-between items-center w-full bottom-0 pr-3">
                <textarea
                  name="text"
                  id="text"
                  placeholder="Write a message"
                  className="text-sm p-2 border border-b-0 w-11/12 resize-none"
                  rows={4}
                  value={typedMessage}
                  onChange={(e) => setTypedMessage(e.target.value)}
                ></textarea>
                <button
                  type="button"
                  className="text-sm bg-secondary p-2 text-white rounded-md"
                  onClick={handleSendMessage}
                >
                  Send
                </button>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-5xl opacity-10 font-bold">Chats</p>
            </div>
          )}
        </section>
      </section>
    </div>
  );
};

export default CustomerChat;
