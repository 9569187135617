import { useEffect, useState } from "react";
import SelectBank from "./SelectBank";
import axios from "axios";
import { API_URL } from "../../App";
import toast from "react-hot-toast";
import { Preloader } from "../elements/Preloader";

export const AddBankModal = ({ setAddBankModal, userToAddBank }: any) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountBankname, setAccountBankname] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loadingAddBank, setLoadingAddBank] = useState(false);

  useEffect(() => {
    if (accountNumber.length === 10 && accountBankname) {
      setTimeout(() => {
        resolveAccountName();
      }, 500);
    }
  }, [accountNumber, accountBankname]);

  const resolveAccountName = async () => {
    setLoading(true);
    const payload = {
      account_number: accountNumber,
      bank_code: accountBankname.code,
    };
    try {
      const response = await axios.post(`${API_URL}/resolveAccount`, payload);
      if (response.data && response.data.status && response.data.data) {
        setLoading(false);
        setAccountName(response.data.data.account_name);
        console.log(response.data.data.account_name);
      } else {
        setLoading(false);
        console.log(
          "Failed to resolve account name:",
          response.data && response.data.message
        );
        toast.error(
          "Failed to resolve account name, Please enter another account"
        );
        setAccountName("");
      }
    } catch (error: any) {
      setLoading(false);
      console.log("Error resolving account name:", error.message);
      setAccountName("");
    }
  };

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (!accountBankname) {
      newErrors.accountBankname = "Please enter your salary account bank name";
      isValid = false;
    }
    if (!accountName) {
      newErrors.accountHolderName = "Please enter your salary account name";
      isValid = false;
    }
    if (accountNumber.length !== 10) {
      newErrors.accountNumber =
        "Please enter correct details to avoid rejection";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAddBank = async () => {
    const payload = {
      account_name: accountName,
      account_number: accountNumber,
      bank_name: accountBankname.name,
      institution: {
        name: accountBankname.name,
        branch: "",
        bank_code: accountBankname.code
    }
    };
    console.log(payload, `${API_URL}/customers/${userToAddBank.id}/linkedBanks`);
    setLoadingAddBank(true);
    if (!validateForm()) {
      toast.error("Please complete bank details");
      setLoadingAddBank(false);
      return;
    }
    try {
      const res = await axios.post(
        `${API_URL}/customers/${userToAddBank.id}/linkedBanks`,
        payload
      );
      if (res.status === 200 || res.status === 201) {
        toast.success(res.data.message || "Bank details added successfully");
      }
      setLoadingAddBank(false);
      setAddBankModal((prev: any) => !prev);
    } catch (error) {
      console.error(error);
      setLoadingAddBank(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 overflow-y-auto">
      <section className="overflow-y-auto bg-white">
        <div className="bg-white p-4 rounded-md">
          <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
            <h2 className="font-semibold text-lg capitalize">
              Add Bank Details of {userToAddBank.name.toLowerCase()}
            </h2>
            <button
              onClick={() => setAddBankModal(false)}
              className="text-dark"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <section>
            <div className="flex items-center justify-between gap-3 mb-3">
              <label htmlFor="bank_name">Bank Name:</label>
              <p
                onClick={toggleModal}
                className="border p-2 text-sm w-[14.5rem] rounded-md cursor-pointer"
              >
                {accountBankname.name ? accountBankname.name : "Select bank..."}
              </p>
              {errors.accountBankname && (
                <p className="text-red-500 text-xs mb-2">
                  {errors.accountBankname}
                </p>
              )}
            </div>
            {isVisible && (
              <SelectBank
                toggleModal={toggleModal}
                bank={accountBankname}
                setBank={setAccountBankname}
              />
            )}
            {accountBankname && (
              <div className="flex items-center justify-between gap-3 mb-3">
                <label htmlFor="account_number">Account Number:</label>
                <input
                  type="text"
                  className="border p-2 rounded-md"
                  name="account_number"
                  id="account_number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
                {errors.accountNumber && (
                  <p className="text-red-500 text-xs mb-2">
                    {errors.accountNumber}
                  </p>
                )}
              </div>
            )}
            {loading && (
              <div className="mt-3">
                <Preloader />
              </div>
            )}
            {accountNumber.length === 10 && !loading && accountName && (
              <div className="flex items-center justify-between gap-3 mb-3">
                <label htmlFor="acctName">Account holder Name:</label>
                <p className="border p-2 text-sm rounded-md ">{accountName}</p>
                {errors.accountBankname && (
                  <p className="text-red-500 text-xs mb-2">
                    {errors.accountBankname}
                  </p>
                )}
              </div>
            )}

            <button
              type="button"
              className="bg-secondary text-white p-2 rounded-md"
              onClick={handleAddBank}
            >
              {loadingAddBank ? <Preloader /> : "Add Bank"}
            </button>
          </section>
        </div>
      </section>
    </div>
  );
};
