import { useState, useEffect } from "react";
import { API_URL } from "../../App";
import axios from "axios";
import toast from "react-hot-toast";
import Modal, { ModalContent } from "../elements/Modal";

const SelectBank = ({ toggleModal, bank, setBank }: any) => {
  const [bankList, setBankList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = async () => {
    try {
      const response = await axios.get(`${API_URL}/getAllBanks`);
      if (response.data && response.data.data) {
        setBankList(response.data.data);
      } else {
        // console.log(
        //   "Failed to fetch banks:",
        //   response.data && response.data.message
        // );
        setBankList([]);
      }
    } catch (error) {
      // console.log("Error fetching banks:", error.message);
      toast.error("Error fetching banks")
    }
  };

  const handleBankChange = (e: any) => {
    const value = e.target.value;
    const selectedBank: any = bankList.find(
      (bankItem: any) => bankItem.bank_name === value
    );

    if (selectedBank) {
      setBank({ name: value, code: selectedBank.bank_code });
    }
    toggleModal();
  };

  return (
    <Modal
      open={true}
      onClose={toggleModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[45%] lg:w-[35%] xl:w-[25%] ">
        <h1 className="font-semibold text-base pb-6">Select Bank</h1>
        <input
          placeholder="Search for bank"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          className="rounded-tl-2xl w-[90%] rounded-br-2xl indent-2 focus:outline-[#008B50] text-xs font-medium border my-1 p-3"
        />
        <select
          className="rounded-tl-2xl w-[90%] rounded-br-2xl indent-2 focus:outline-[#008B50] text-xs font-medium border my-1 p-3"
          name="bank"
          id="bank"
          value={bank}
          onChange={handleBankChange}
          size={10}
        >
          <option value="">Select bank...</option>
          {bankList
            .filter((bankItem: any) =>
              bankItem.bank_name
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            )
            .map((filteredBankItem: any, index) => (
              <option key={index} value={filteredBankItem.bank_name}>
                {filteredBankItem.bank_name}
              </option>
            ))}
        </select>
      </ModalContent>
    </Modal>
  );
};

export default SelectBank;
