import { FormEvent, useState, ChangeEvent } from "react";
import { GoEyeClosed, GoEye } from "react-icons/go";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { login } from "../../redux/thunk";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

interface Errors {
  email?: string;
  password?: string;
}

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Errors>({});
  const { status } = useAppSelector((store: RootState) => store.auth);
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    if (inputValue.email.trim() === "") {
      newErrors.email = "Please enter a valid email address or username";
      isValid = false;
    }

    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(inputValue.password)) {
      newErrors.password =
        "Password must be at least 8 characters and contain both letters and numbers";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading((prev) => !prev);
    console.log(" Start time:",  new Date().getMinutes() + ":" + new Date().getSeconds());
    const loginData = {
      email: inputValue.email.toLowerCase(),
      password: inputValue.password,
    };
    if (validateForm()) {
      await dispatch(login(loginData))
        .then((res) => {
            if(res.type === "login/fulfilled"){
                navigate("/dashboard");
                toast.success("Login successfull");
            }
        })
        setIsLoading(false)
    }
    console.log("end time:", new Date().getMinutes() + ":" + new Date().getSeconds());
  };

  return (
    <div className="flex items-center justify-center h-[100vh]">
      <div className="px-4 lg:px-14 w-full lg:w-[45%] mt-10 lg:mt-[10rem]">
        <div className="flex items-center justify-center">
          <img
            src="assets/logo.png"
            alt="logo"
            className="pb-15 self-center w-[6rem] "
          />
        </div>
        <div className="flex items-center justify-center">
          <h1 className="font-bold text-xl mb-3 mt-9">
            Log in to your account
          </h1>
        </div>
        <form className="px-4 lg:px-14 mb-[5rem]" onSubmit={handleLogin}>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium pb-1">Email</label>
            <input
              name="email"
              value={inputValue.email}
              onChange={handleInputChange}
              type="text"
              placeholder="Enter your correct email address"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
            {errors.email && (
              <p className="text-red-500 text-xs">{errors.email}</p>
            )}
          </div>
          <div className="flex flex-col relative mb-2">
            <label className="text-sm font-medium pb-1">Password</label>
            <input
              name="password"
              value={inputValue.password}
              onChange={handleInputChange}
              type={showPassword ? "text" : "password"}
              placeholder="Enter your correct email address"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
            <figure
              className="absolute right-4 top-[65%] transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <GoEye /> : <GoEyeClosed />}
            </figure>
          </div>
          {errors.password && (
              <p className="text-red-500 text-xs">{errors.password}</p>
            )}
          <button
            className="bg-secondary text-white w-[100%] text-base font-semibold p-2 lg:p-3 rounded-md shadow-sm mt-8 lg:mt-10 mb-6 transition-all duration-500 hover:border-secondary hover:border-2 hover:text-secondary hover:bg-transparent"
            type="submit"
          >
            {isLoading ? <LoadingSpinner /> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
