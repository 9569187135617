import { useEffect, useState } from "react";
import { DeliveryDetails } from "../../components/modals/DeliveryDetails";
import axios from "axios";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { API_URL } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

export const AssignedOrders = () => {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [assignedOrders, setAssignedOrders] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const fetchAssignedOrders = async () => {
    setIsLoading((prev) => !prev);
    try {
      const res = await axios.get(
        `${API_URL}/getAssignedOrdersToRider`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data.orders);

      setIsLoading((prev) => !prev);
      setAssignedOrders(res.data.orders);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchAssignedOrders();
  }, []);

  const handleUndoAssignment = async (id: string) => {
    try {
      const res = await axios.delete(
        `${API_URL}/undoAssignment/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message || "Assignment undone!");
      fetchAssignedOrders();
    } catch (error: any) {
      toast.error(error.message || "Failed to undo assignment");
    }
  };

  const handleOrder = (
    items: object,
    deliveryLocation: string,
    pickUpLocation: string
  ) => {
    setShowOrderDetails((prev) => !prev);
    setOrderDetails({
      items,
      deliveryLocation,
      pickUpLocation,
    });
  };

  return (
    <main>
      <div className="bg-white p-3 rounded-md my-3">
        <h1 className="font-bold text-lg">Assigned Orders</h1>
      </div>
      <section className="overflow-x-auto">
        <table className="md:w-full w-[700px] text-center md:text-base text-sm">
          <thead>
            <tr className="bg-gray-50 font-bold h-12">
              <th>SN</th>
              <th>Orders</th>
              <th>Assigned Rider</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={4} className="mx-auto">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : assignedOrders.length > 0 ? (
              assignedOrders.map((item: any, index: number) => (
                <tr key={index} className="border-b-2">
                  <td>{index + 1}</td>
                  <td
                    className="p-2 cursor-pointer hover:underline hover:text-secondary"
                    onClick={() =>
                      handleOrder(
                        item.orderItems,
                        item.deliveryDetails,
                        item.pickUpDetails
                      )
                    }
                  >
                    {item.customerLastName + " " + item.customerFirstName}
                  </td>
                  <td className="p-2">
                    {item.riderFirstName + " " + item.riderLastName}
                  </td>
                  <td className="p-2">
                    <button
                      type="button"
                      className={`md:text-sm text-xs bg-secondary p-2 text-white rounded-lg hover:bg-green-700 ${
                        isLoading ? "bg-[#25a871]" : "bg-secondary"
                      }`}
                      onClick={() => handleUndoAssignment(item.orderId)}
                      disabled={isLoading}
                    >
                      Undo Assignment
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="p-5 bg-white text-secondary">
                  No Assigned Orders!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {showOrderDetails && (
        <DeliveryDetails
          showOrderDetails={showOrderDetails}
          setShowOrderDetails={setShowOrderDetails}
          orderDetails={orderDetails}
        />
      )}
    </main>
  );
};
