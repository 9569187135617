import { useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner";
import axios from "axios";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { API_URL } from "../../App";

interface Errors {
  email?: string;
  confirmPassword?: string;
  password?: string;
}

const Signup = () => {
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputValue.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(inputValue.password)) {
      newErrors.password =
        "Password must be at least 8 characters and contain both letters and numbers";
        isValid = false;
    }

    if (inputValue.password !== inputValue.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateAccount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const payload = {
        fullName: inputValue.fullName,
        email: inputValue.email,
        phoneNumber: inputValue.phoneNumber,
        password: inputValue.password,
      };
  
      try {
        const response = await axios.post(`${API_URL}/register`, payload);
        toast.success(response.data.message);
        navigate("/");
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        toast.error("An error occurred during registration")
        console.error("An error occurred during registration:", error);
      }
    }
  };

  return (
    <div className="flex items-center justify-beteen h-[100vh]">
      <div className="px-4 lg:px-14 w-full lg:w-[45%] mt-10 lg:mt-[20rem]">
        <img src="assets/logo.png" alt="logo" className="pb-20" />
        <form
          className="px-4 lg:px-14 mb-[5rem]"
          onSubmit={handleCreateAccount}
        >
          <h1 className="font-bold text-xl mb-16">Create your account</h1>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium pb-1">Full Name</label>
            <input
              type="text"
              name="fullName"
              value={inputValue.fullName}
              onChange={handleInputChange}
              required
              placeholder="Enter your Full Name"
              className="bg-gray-100 outline-none text-sm p-2 lg:p-3 rounded-md border border-gray-200"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium pb-1">Email</label>
            <input
              name="email"
              value={inputValue.email}
              onChange={handleInputChange}
              type="text"
              required
              placeholder="Enter your correct email address"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
            {errors.email && (
              <p className="text-red-500 text-xs">{errors.email}</p>
            )}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium pb-1">Phone Number</label>
            <input
              name="phoneNumber"
              value={inputValue.phoneNumber}
              onChange={handleInputChange}
              type="text"
              required
              placeholder="Enter your correct Phone Number"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium pb-1">Password</label>
            <input
              name="password"
              value={inputValue.password}
              onChange={handleInputChange}
              type="text"
              required
              placeholder="Enter your correct email address"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
            {errors.password && (
              <p className="text-red-500 text-xs">{errors.password}</p>
            )}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium pb-1">Confirm Password</label>
            <input
              name="confirmPassword"
              value={inputValue.confirmPassword}
              onChange={handleInputChange}
              type="text"
              required
              placeholder="Enter your correct email address"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-xs">{errors.confirmPassword}</p>
            )}
          </div>
          <button
            className="bg-secondary text-white w-[100%] text-base font-semibold p-2 lg:p-3 rounded-md shadow-sm mt-8 lg:mt-12 mb-6 transition-all duration-500 hover:border-secondary hover:border-2 hover:text-secondary hover:bg-transparent"
            type="submit"
          >
         {isLoading ? <LoadingSpinner /> : "Register"}   
          </button>
          <p className="text-sm text-center">
            Already have an account?{" "}
            <a href="/">
              <span className="text-secondary underline">Login</span>
            </a>
          </p>
        </form>
      </div>
      <div>
        <Banner />
      </div>
    </div>
  );
};

export default Signup;
