import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { verificationStageOne } from "../../redux/thunk";
import BVNVerificationModal from "../../components/modals/BVNVerificationModal";
import SelfEmployed from "../../components/modals/SelfEmployed";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../../App";

export const SelfEmployedVerificationStage1 = () => {
  const [isLoading] = useState(false);
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [businessModalOpen, setBusinessModalOpen] = useState(false);
  const [selectedBvn, setSelectedBvn] = useState<any>({});
  const [selectedBusiness, setSelectedBusiness] = useState<any>({});
  const [actionSelected, setActionSelected] = useState("");
  const [disapproveMsg, setDisapproveMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [id, setId] = useState("");

  const dispatch = useAppDispatch();
  const { selfEmployed } = useAppSelector((store) => store.verification);
  const { token } = useAppSelector((store) => store.auth);

  useEffect(() => {
    if (selfEmployed.length === 0) {
      dispatch(verificationStageOne());
    }
  }, [dispatch, selfEmployed.length]);

  const handleAction = (e: any) => {
    setActionSelected(e.target.value);
  };

  const handleClearSelection = () => {
    setActionSelected("");
    const selectElement = document.getElementById("select") as any;
    if (selectElement) {
      selectElement.selectedIndex = 0;
    }
  };

  const handleBvnClick = ({
    firstName,
    middleName,
    lastName,
    gender,
    bvn,
    image,
    address,
    dateOfBirth,
    stateOfOrigin,
  }: any) => {
    setSelectedBvn({
      firstName,
      middleName,
      lastName,
      gender,
      bvn,
      image,
      dateOfBirth,
      address,
      stateOfOrigin,
    });
    setBvnModalOpen(true);
  };

  const handleBusinessClick = (business: any) => {
    setSelectedBusiness(business);
    setBusinessModalOpen(true);
  };

  const handleDisapproveClick = (msg: string) => {
    setDisapproveMsg(msg);
    setShowMsg(true);
  };

  const approveCustomerHandler = async () => {
    try {
      const res = await axios.post(
        `${API_URL}/approveStageOneProcess/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setId("");
      setActionSelected("");
      toast.success(res.data.message);
      dispatch(verificationStageOne())
    } catch (error) {
      console.log(error);
      setActionSelected("");
      toast.error("Error approving user, please try again later");
    }
  };

  const disaproveCustomerHandler = async () => {
    if (!disapproveMsg) return;
    const payload = {
      reason: disapproveMsg,
    };
    try {
      const res = await axios.post(
        `${API_URL}/disapproveStageProcess/${id}`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setId("");
      setDisapproveMsg("");
      setActionSelected("");
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setActionSelected("");
    }
  };

  return (
    <main className="overflow-x-auto w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">
              Self-Employed Verification Stage 1(Bvn & Job Details Check)
            </h1>
          </div>
          <section className="overflow-x-auto">
            <table className="w-full" style={{ minWidth: "1200px" }}>
              <thead className="bg-gray-50 text-left">
                <tr>
                  <th className="p-2">S/N</th>
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2 ">BVN</th>
                  <th className="p-2">Business</th>
                  <th className="p-2">Action</th>
                  <th className="p-2">Status</th>
                </tr>
              </thead>
              <tbody className="px-4">
                {selfEmployed.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={7} className="p-10 text-secondary">
                      No pending verification!
                    </td>
                  </tr>
                ) : (
                  selfEmployed.map((data, index) => (
                    <tr
                      className="border-b py-2"
                      key={index}
                    >
                      <td className="text-secondary p-2">{index + 1}</td>
                      <td className="p-2">
                        {data.lastName}
                        {data.firstName}
                      </td>
                      <td className="p-2">{data.email}</td>
                      <td className="p-2">
                        <button
                          onClick={() =>
                            handleBvnClick({
                              firstName: data.firstName,
                              middleName: data.middleName,
                              lastName: data.lastName,
                              bvn: data.bvn,
                              gender: data.gender,
                              image: data.image,
                              address: data.address,
                              dateOfBirth: data.dateOfBirth,
                              stateOfOrigin: data.stateOfOrigin,
                            })
                          }
                          className="text-blue-500 hover:underline focus:outline-none"
                        >
                          {data.bvn}
                        </button>
                      </td>
                      <td className="p-2">
                        <button
                          onClick={() =>
                            handleBusinessClick(data.selfEmployed)
                          }
                          className="text-blue-500 hover:underline focus:outline-none"
                        >
                          {data.selfEmployed.businessName}
                        </button>
                      </td>
                      <td className="p-2">
                        <select
                          name="select"
                          id="select"
                          className="cursor-pointer"
                          onChange={(e) => {
                            handleAction(e);
                            setId(data._id);
                          }}
                          disabled={data.adminVerification === "dstage1"}
                        >
                          <option value="">Select an action</option>
                          <option value="approve">Approve</option>
                          <option value="disapprove">Disapprove</option>
                        </select>
                      </td>
                      <td className="p-2">
                        <button
                          onClick={
                            data.msg
                              ? () => handleDisapproveClick(data.msg)
                              : undefined
                          }
                          className={
                            data.adminVerification !== "stage1"
                              ? "text-red-600"
                              : "text-yellow-400"
                          }
                        >
                          {data.adminVerification !== "stage1"
                            ? "Disapproved"
                            : "Pending..."}
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
      {showMsg && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setShowMsg(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>{disapproveMsg}</p>
              <div>
                <button
                  type="button"
                  onClick={() => setShowMsg(false)}
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {actionSelected === "approve" && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={handleClearSelection}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>Are you sure you want approve this user?</p>
              <div>
                <button
                  type="button"
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                  onClick={approveCustomerHandler}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={handleClearSelection}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {actionSelected === "disapprove" && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between items-center flex-col text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={handleClearSelection}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>Are you sure you want disapprove this user?</p>
              <label htmlFor="reason" className="text-left">
                Please state your reason
              </label>
              <textarea
                name="reason"
                id="reason"
                className="border my-4 text-sm p-2"
                placeholder="What is your reason?"
                value={disapproveMsg}
                onChange={(e) => setDisapproveMsg(e.target.value)}
                cols={30}
                rows={5}
              ></textarea>
              <div>
                <button
                  type="button"
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                  onClick={disaproveCustomerHandler}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={handleClearSelection}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {bvnModalOpen && (
        <BVNVerificationModal
          selectedBvn={selectedBvn}
          setBvnModalOpen={setBvnModalOpen}
        />
      )}
      {businessModalOpen && (
        <SelfEmployed
          selectedInfo={selectedBusiness}
          setSelfEmployedModalOpen={setBusinessModalOpen}
        />
      )}
    </main>
  );
};
