import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

export const Restaurants = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [approvedRestaurant, setApprovedRestaurant] = useState(0)
  const [pendingRestaurant, setPendingRestaurant] = useState(0)
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getAllRestaurants = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `${API_URL}/getAllRestaurant`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAllRestaurants(res.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    getAllRestaurants();
  }, [token]);

  useEffect(() => {
    let approvedCount = 0;
    let pendingCount = 0;

    allRestaurants.forEach((item: any) => {
      if (item.isApproved === "pending") {
        pendingCount++;
      } else {
        approvedCount++;
      }
    });

    setApprovedRestaurant(approvedCount);
    setPendingRestaurant(pendingCount);
  }, [allRestaurants]);


  const filteredRestaurants = allRestaurants.filter((restaurant: any) =>
    restaurant.restaurantName.toLowerCase().includes(search.toLowerCase())
  );
  
  return (
    <main>
      <div className="flex justify-between">
        <h2 className="font-bold text-lg">Restaurants</h2>
        <button
          type="button"
          className="bg-secondary rounded-md text-white text-sm p-3"
          onClick={() => navigate("add-new-restaurant")}
        >
          Add New Restaurant
        </button>
      </div>
      <section className="flex mt-5 justify-evenly flex-wrap gap-3">
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Restaurants
          </h2>
          <p className="font-semibold text-3xl">{allRestaurants.length}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Approved Restaurants
          </h2>
          <p className="font-semibold text-3xl">{approvedRestaurant}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Restaurants pending
          </h2>
          <p className="font-semibold text-3xl">{pendingRestaurant}</p>
        </div>
      </section>
      <section className="my-5 overflow-x-scroll">
        <section className="md:w-full w-[700px]">
          <div className="flex justify-between items-center w-full bg-white rounded-lg p-3 mb-2 gap-2">
            <h1 className="font-semibold ">All Restaurants</h1>
            <input
              type="search"
              name="search"
              id="search"
              className="border text-sm w-1/2 p-2 rounded"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search by name"
            />
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <table className="w-full text-left">
              <thead>
                <tr className="bg-gray-50 font-bold h-12 md:text-base text-sm">
                  <th>SN</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allRestaurants.length > 0 ? (
                  filteredRestaurants.map((item: any, index: any) => (
                    <tr key={index} className="border-b-2 hover:bg-gray-200">
                      <td className="md:text-base text-sm">{index + 1}</td>
                      <td
                        className="cursor-pointer"
                        onClick={() =>
                          navigate("restaurant-information", {
                            state: { id: item.id, name: item.restaurantName },
                          })
                        }
                      >
                        <div className="w-12 h-12 my-1">
                          <img
                            src={item.restaurantImage}
                            className="w-full h-full"
                            alt={item.restaurantImage}
                          />
                        </div>
                      </td>
                      <td
                        className="cursor-pointer md:text-base text-sm"
                        onClick={() =>
                          navigate("restaurant-information", {
                            state: { id: item.id, name: item.restaurantName },
                          })
                        }
                      >
                        {item.restaurantName}
                      </td>
                      <td
                        className="cursor-pointer md:text-base text-sm"
                        onClick={() =>
                          navigate("restaurant-information", {
                            state: { id: item.id, name: item.restaurantName },
                          })
                        }
                      >
                        {item.restaurantEmail}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="bg-secondary text-white text-sm p-2 rounded-md"
                          onClick={() => navigate("settlement", {
                            state: { name: item.restaurantName },
                          })}
                        >
                          Settlement
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="p-5 bg-white text-center text-secondary"
                    >
                      No Restaurants!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </section>
      </section>
    </main>
  );
};
