import { useEffect, useState } from "react";
import { BsCalendar } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { DeliveryDetails } from "../../components/modals/DeliveryDetails";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logisticAssignDetails } from "../../redux/thunk";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../../App";
import { RootState } from "../../redux/store";

export const AssignDelivery = () => {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [riderId, setRiderId] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ordersToAssign } = useAppSelector((store) => store.logistics);
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [dates, setDates] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(logisticAssignDetails()).then(() => {
      if (ordersToAssign.orders) {
        setDates(Array(ordersToAssign.orders.length).fill(""));
        setRiderId(Array(ordersToAssign.orders.length).fill(""));
      }
    });
  }, []);

  const handleDateChange = (e: any, index: any) => {
    const newDates = [...dates];
    newDates[index] = e.target.value;
    setDates(newDates);
  };

  const handleRiderSelect = (e: any, index: any) => {
    const newRiderId = [...riderId];
    newRiderId[index] = e.target.value;
    setRiderId(newRiderId);
  };

  const handleOrder = (
    items: object,
    deliveryLocation: string,
    pickUpLocation: string
  ) => {
    setShowOrderDetails((prev) => !prev);
    setOrderDetails({
      items,
      deliveryLocation,
      pickUpLocation,
    });
  };

  const handleAssign = async ({
    orderId,
    pickUp,
    dropOff,
    riderId,
    deliveryDate,
  }: any) => {
    const payload = {
      orderId,
      pickUp,
      dropOff,
      riderId,
      deliveryDate: deliveryDate.toISOString(),
    };

    try {
      setIsLoading((prev) => !prev);
      const res = await axios.post(
        `${API_URL}/logisticAssignRiderDelivery`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message || "Order assigned successfully!");
      dispatch(logisticAssignDetails());
      setIsLoading((prev) => !prev);
      console.log(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.message || "Assignment failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <div className="bg-white p-3 my-3 rounded-md flex justify-between items-center">
        <h1 className="font-bold text-lg">Assign Delivery</h1>
        <button
          type="button"
          className="bg-secondary p-2 text-xs md:text-sm text-white rounded-lg hover:bg-green-700"
          onClick={() => navigate("/dashboard/logistics/assigned-orders")}
        >
          Assigned Orders
        </button>
      </div>
      <section className="overflow-x-auto">
        <table className="text-left md:w-full w-[700px] md:text-base text-sm">
          <thead>
            <tr className="bg-gray-50 font-bold h-12">
              <th className="pl-2">SN</th>
              <th className="pl-2">Orders</th>
              <th className="pl-2">Select delivery date</th>
              <th className="pl-2">Riders</th>
              <th className="pl-2"></th>
            </tr>
          </thead>
          <tbody>
            {ordersToAssign &&
            ordersToAssign.orders &&
            ordersToAssign.orders.length > 0 ? (
              ordersToAssign.orders.map((item: any, index: number) => (
                <tr key={index} className="border-b-2">
                  <td>{index + 1}</td>
                  <td
                    className="p-2 cursor-pointer hover:underline hover:text-secondary text-sm"
                    onClick={() =>
                      handleOrder(
                        item.orderItems,
                        item.deliveryDetails,
                        item.pickUpDetails
                      )
                    }
                  >
                    {item.customerLastName + " " + item.customerFirstName}
                  </td>
                  <td className="relative">
                    <span
                      className={`absolute top-3 xl:left-14 lg:left-6 md:left-10 right-10 p-2 text-sm ${
                        dates[index] === "" ? "invisible" : "visible border"
                      }`}
                    >
                      {dates[index]}
                    </span>
                    <span className="absolute top-5">
                      <input
                        type="date"
                        name="date"
                        id="date"
                        value={dates[index]}
                        onChange={(e) => handleDateChange(e, index)}
                        className="w-6 top-[-6px] right-[-4px] text-2xl absolute opacity-0"
                      />
                      <BsCalendar />
                    </span>
                  </td>
                  <td className="p-2">
                    <select
                      name="riders"
                      id="riders"
                      className="text-sm p-2 w-32 overflow-hidden truncate cursor-pointer"
                      onChange={(e) => handleRiderSelect(e, index)}
                    >
                      <option value="">select a rider</option>
                      {ordersToAssign.riders &&
                        ordersToAssign.riders.map(
                          (item: any, index: number) => (
                            <option value={item.riderId} key={index}>
                              {item.riderFirstName + " " + item.riderLastName}
                            </option>
                          )
                        )}
                    </select>
                  </td>
                  <td className="p-2">
                    <button
                      type="button"
                      className="md:text-sm text-xs p-2 text-white rounded-lg bg-secondary"
                      onClick={() =>
                        handleAssign({
                          orderId: item.orderId,
                          pickUp: item.pickUpDetails,
                          dropOff: item.deliveryDetails,
                          riderId: riderId[index],
                          deliveryDate: new Date(dates[index]),
                        })
                      }
                      disabled={isLoading}
                    >
                      Assign
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-secondary p-3 text-center">
                  No deliveries to assign!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {showOrderDetails && (
        <DeliveryDetails
          showOrderDetails={showOrderDetails}
          setShowOrderDetails={setShowOrderDetails}
          orderDetails={orderDetails}
        />
      )}
    </main>
  );
};
