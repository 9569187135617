import { useState } from "react";
import { API_URL } from "../../App";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import toast from "react-hot-toast";
import { Preloader } from "../elements/Preloader";
import { getFees } from "../../redux/thunk";

const ServiceModal = ({ setShowServiceModal }: any) => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [serviceFee, setServiceFee] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    const payload = {
      serviceFee,
      deliveryFee,
      interest: interestRate,
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${API_URL}/createFees`, payload, {
        headers: {
          Authorization: token,
        },
      });
      console.log("res", res);
      setIsLoading(false);
      
      dispatch(getFees())
      setServiceFee("");
      setDeliveryFee("");
      setInterestRate("");
      setShowServiceModal(false);  
      toast.success(res.data.message || "Updated successfully");
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message || "Failed to update");
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="text-dark p-2 rounded-t-md mb-4">
          <div className="relative w-full">
            <h2 className="font-bold text-xl">Set Service and Delivery Fee</h2>
            <button
              onClick={() => setShowServiceModal(false)}
              className="text-dark absolute right-0 top-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div>
            <div className="flex justify-between my-2">
              <label htmlFor="service-fee">Service Fee:</label>
              <input
                className="border p-2 "
                type="number"
                name="service-fee"
                id="service-fee"
                value={serviceFee}
                onChange={(e) => setServiceFee(e.target.value)}
              />
            </div>
            <div className="flex justify-between my-2">
              <label htmlFor="delivery-fee">Delivery Fee:</label>
              <input
                className="border p-2 "
                type="number"
                name="delivery-fee"
                id="delivery-fee"
                value={deliveryFee}
                onChange={(e) => setDeliveryFee(e.target.value)}
              />
            </div>
            <div className="flex justify-between my-2">
              <label htmlFor="interestRate">Interest rate:</label>
              <input
                className="border p-2 "
                type="number"
                name="interestRate"
                id="interestRate"
                value={interestRate}
                onChange={(e) => setInterestRate(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-5 py-3 mr-3 rounded text-white bg-secondary"
            >
              {isLoading ? <Preloader /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
